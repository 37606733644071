import React from 'react';
import '../styles/MenuTop.css';
import { serviceFetch } from '../utils/serviceFetch';
import { CheckIcon, QuestionCircleIcon } from '../components/BootstrapIcons';


// function ItemMenu({ texto }) {
//     return <span>{texto}</span>
// }

function MenuTopContainer() {

    const [showSoporte, setShowSoporte] = React.useState(false);

    const handleCloseSession = () => {
        let formData = new FormData();
        formData.append('csrfmiddlewaretoken', localStorage.getItem('csrf_token'));
        serviceFetch('logout/', 'POST', formData)
            .then((res) => {
                if (res.status === 401)
                    throw new Error('No autorizado');
                return res.json();
            })
            .then((data) => {
                localStorage.removeItem('csrf_token');
                if(document.cookie)
                    document.cookie = '';
                window.location.href = '/';
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="MenuTop">
            <img src={"https://godemos.cl/static/desktop/assets/img/logo.webp"} alt='godemos' className='MenuTop-Logo' />
            <div className="MenuTop-Grupo">
                {/* <ItemMenu texto='INICIO' />
                <ItemMenu texto='SOPORTE' />
                <ItemMenu texto='BLOG' /> 
                <span><InfoCircleIcon size="21px" /></span>*/}
                <span style={{cursor:'pointer'}} onClick={e => setShowSoporte(!showSoporte)}><QuestionCircleIcon size="21px" /></span>
                <button className='MenuTop-CerrarSesion' onClick={handleCloseSession}>Cerrar Sesión</button>
            </div>
            {showSoporte && <div className='MenuTop-Notification'>
                <p><b>¿Necesitas ayuda?</b></p>
                <p>Conoce más detalles y resuelve todas tus dudas en cuanto a planes, precios y soporte técnico en nuestro <a href="https://wa.link/k33yhs" target='_blank' rel='noreferrer'>Whatsapp</a>.</p>
                <p>También nos puedes escribir al correo electrónico <a href="mailto:contacto@godemos.cl" target='_blank' rel='noreferrer'>contacto@godemos.cl</a></p>
                <p><span style={{cursor:'pointer'}} onClick={e => setShowSoporte(false)}><CheckIcon /></span></p>
            </div>}
        </div>
    );
}

export default MenuTopContainer;
