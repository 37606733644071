import React, { useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { CheckIcon, CloseIcon, CloudArrowUp,CopyIcon,FileDocIcon } from "../components/BootstrapIcons.jsx";
import { backendRoot } from "../contexts/BackendProps.jsx";

const CastingListComponent = ({ collection, dataLocutor, setParticipar }) => {

    const [casting, setCasting] = useState(null);

    const mapCasting = (item, index) => (
        <CastingItemComponent 
            key={index}
            index={index} 
            item={item} 
            dataLocutor={dataLocutor} 
            setParticipar={setParticipar}
            onIconClick={()=>setCasting(item)}
            />
    );

    return (<>
        {casting && <CastingComponent item={casting} dataLocutor={dataLocutor} onCloseClick={()=>setCasting(null)} />}
        <div className="CastingListComponent">
            <div>
                <div>
                    
                </div>
                <div>
                    <b>Título</b>
                </div>
                <div>
                    <b>Cliente</b>
                </div>
                <div>
                    <b>Deadline</b>
                </div>
                <div>
                    <b>Estado</b>
                </div>
                <div>
                    <b>Audición</b>
                </div>
            </div>
            {collection.map(mapCasting)}
        </div>
    </>
    );
};

const CastingItemComponent = ({ index, item, dataLocutor, setParticipar, onIconClick = ()=>{} }) => {    
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [audicion, setAudicion] = useState(null);
    const [reload, setReload] = useState(0);
    const {loading, error, data} = useFetch(`audicion/${item.id}`, {}, [reload]);

    const setAudicionResponse = (response) => {
        console.info(response);
        setReload(reload+1);
    }

    useEffect(() => {
        if (data && data.audicion) {
            setAudicion(`${data.audicion}?${Math.random()}`);
        }
    }, [data]);

    return (
        <div key={index}>
            <div><FileDocIcon size="32" onClick={onIconClick} style={{cursor: 'pointer'}} /></div>
            <div onClick={onIconClick} style={{cursor: 'pointer'}}>{item.titulo}</div>
            <div onClick={onIconClick} style={{cursor: 'pointer'}}>{item.ClienteCasting.nombre}</div>
            <div>{new Date(item.deadline).toLocaleDateString()}</div>
            <div>
            {data && !data.estado && <>-</>}
            {data && data.estado && <>{data.estado}</>}
            </div>
            <div>
                {loading && <p>Cargando...</p>}
                {error && <p>Error: {error}</p>}
                <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'}
                    }>
                    {data && audicion && 
                        <audio src={`${backendRoot}/${audicion}`} controls style={{display: 'block'}}></audio>
                    }
                    {data && uploading && uploadProgress!==1 && <p>Subiendo audio ({Math.round(uploadProgress*100)}%)...</p>}
                    {data && uploading && uploadProgress===1 && <p>Procesando audio...</p>}
                    {data && !uploading &&
                        <label>
                            <span><CloudArrowUp size="32" style={{cursor: 'pointer',marginRight: '20px'}} /></span>
                            <input style={{display: 'none'}} type="file" accept="audio/*" onChange={(e)=>setParticipar(item.id, e.target.files[0], setUploading, setUploadProgress, setAudicionResponse)} />
                        </label>
                    }
                </div>
                {data && !data.audicion && uploading && <progress value={uploadProgress} max="1"></progress>}
            </div>
        </div>
    );
};

const CastingComponent = ({ item, dataLocutor, onCloseClick }) => {
    
    const [copied, setCopied] = useState(false);

    const handleTextCopy = (e) => {
        navigator.clipboard.writeText(item.texto).then(function() {
            setCopied(true);
        }).catch(function(error) {
            console.error('Error copying text: ', error);
        });
    };
    return (<div className="CastingComponent">
        <div>
            <CloseIcon onClick={onCloseClick} style={{cursor: 'pointer'}} />
        </div>
        <hr/>
        <div>
            <b>Título:</b><br/>
            <span>{item.titulo}</span>
        </div>
        <div>
            <b>Cliente:</b><br/>
            <span>{item.ClienteCasting.nombre}</span>
        </div>
        <div>
            <b>Descripción:</b><br/>
            <span>{item.descripcion}</span>
        </div>
        <div>
            <b>Texto:</b> {!copied && <small onClick={handleTextCopy} style={{cursor: 'pointer'}}><CopyIcon size="16" /> copiar</small>} {copied && <CheckIcon size="16"/>}<br/>
            <span style={{whiteSpace: 'pre-wrap'}}>{item.texto}</span>
        </div>
        <div>
            <b>Deadline:</b><br/>
            <span>{new Date(item.deadline).toLocaleDateString()}</span>
        </div>
        <div>
            <b>Audio de Referencia:</b><br/>
            {item.audio_referencia==='' && <span>Sin audio de referencia</span>}
            {item.audio_referencia!=='' &&
                <><audio src={`${backendRoot}/${item.audio_referencia}`} controls style={{display: 'block'}}></audio><br/></>
            }
        </div>
        <div>
            <b>Género:</b><br/>
            <span>{item.genero}</span>
        </div>
        <div>
            <b>Acento:</b><br/>
            <span>{item.acento}</span>
        </div>
        <div>
            <b>Requisitos:</b><br/>
            <span>{item.requisitos}</span>
        </div>
        <div>
            <b>Pago Locutor:</b><br/>
            <span>{item.pago_locutor}</span>
        </div>
        <div>
            <b>Fecha de Creación:</b><br/>
            <span>{new Date(item.created).toLocaleDateString()}</span>
        </div>

    </div>);
};

export default CastingListComponent