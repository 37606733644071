import React from "react";
import "../styles/views/LoginView.css";
//import { backendRoot } from "../contexts/BackendProps";
import { serviceFetch } from "../utils/serviceFetch";

function LoginView({handleOnSubmit}) {

    const inputUser = React.createRef();

    const handleRecoverPassword = () => {
        if (inputUser.current.value === "") {
            alert("Debe ingresar un usuario");
            return;
        }

        if(!window.confirm(`¿Desea reestablecer la contraseña de la cuenta "${inputUser.current.value}"?`))
            return;

        serviceFetch(`reestablecer_contrasena/${inputUser.current.value}`)
            .then((res) => {
                if (res.status === 200) {
                    alert("Se ha enviado un correo con las instrucciones para reestablecer su contraseña");
                } else {
                    throw new Error("No se ha podido reestablecer la contraseña");
                }
            })
            .catch((error) => {
                console.log(error);
                alert(error.message);
            });
    };

    return (
        <div className="LoginView">
            <p><img src="https://godemos.cl/static/desktop/assets/img/logo.webp" alt="godemos" class="MenuTop-Logo"></img></p>
            <form onSubmit={handleOnSubmit}>
                <input ref={inputUser} type="text" name="username" placeholder="Correo electrónico" />
                <input type="password" name="password" placeholder="Contraseña" />
                <button type="submit">Ingresar</button>
                <span onClick={handleRecoverPassword} style={{cursor:'pointer'}}>Reestablecer contraseña</span>
            </form>
        </div>
    );
}
 
export default LoginView;